<template>
  <div>
    <ui-component-modal
      :modalTitle="$t('Components.Vouchers.Settings.Title_EditSettings')"
      modalSize="large"
      :onClickCancel="onClickCancel"
      :showModal="showModal"
      :onClickSave="saveVoucher"
      :isSaving="isSaving"
      :isSavingSuccess="isSavingSuccess"
      :isSavingError="isSavingError"
      :hideFooterButtons="isSavingError || isSavingSuccess"
      @closeModal="onClickCancel"
    >
      <template v-slot:content>
        <message
          :type="'is-info'"
          :message="$t('Components.Vouchers.Settings.Error_Text')"
        />

        <div class="form-settings">
          <div class="column">
            <BaseFieldTemplate
              v-if="voucher.Type === 'Credit'"
              :label="$t('Components.Vouchers.Settings.Label_Credits')"
              :labelForInput="false"
              fieldClasses="has-addons"
            >
              <template v-slot:fields>
                <p class="control">
                  <a
                    class="button is-static"
                    v-html="locationState.CurrencySymbol"
                  ></a>
                </p>
                <p class="control">
                  <input
                    type="text"
                    v-model.number="mVoucher.Credit"
                    class="input"
                  />
                </p>
              </template>
            </BaseFieldTemplate>

            <ui-base-input-field
              v-if="voucher.Type === 'Voucher'"
              v-model="mVoucher.SeatsCount"
              :labelForInput="false"
              :label="$t('Components.Vouchers.Settings.Label_SeatsAmount')"
            />

            <BaseFieldTemplate
              v-if="voucher.Type === 'Action'"
              :label="
                $t('Components.Vouchers.Settings.Label_VoucherOncePerPerson')
              "
              :labelForInput="false"
            >
              <template v-slot:fields>
                <ui-base-checkbox v-model="mVoucher.IsActionVoucher" />
              </template>
            </BaseFieldTemplate>

            <BaseFieldTemplate
              :label="$t('Components.Vouchers.Settings.Label_UsedWhenBooking')"
              :labelForInput="false"
            >
              <template v-slot:fields>
                <ui-base-checkbox
                  v-for="(meetingtype, index) in meetingtypes"
                  :class="{ 'has-margin-left': index }"
                  :key="meetingtype.MeetingtypeId"
                  :label="meetingtype.MeetingtypeId | getMeetingtypeName"
                  v-model="mVoucher.MeetingtypeIds"
                  :checkValue="meetingtype.MeetingtypeId"
                />
              </template>
            </BaseFieldTemplate>
          </div>

          <div class="column">
            <ui-base-date-field
              v-model="voucherValidDates"
              :labelForInput="false"
              :openCalendarInModal="true"
              :isRange="true"
              @updateDates="setVoucherValidDates"
              :screens="{ default: 1, sm: 2 }"
              :label="$t('Components.Vouchers.Settings.Label_ValidityPeriod')"
            />

            <div class="row">
              <ui-base-input-field
                v-model="mVoucher.MinSeats"
                :labelForInput="false"
                :label="$t('Components.Vouchers.Settings.Label_MinimalSeats')"
              />
              <ui-base-input-field
                v-model="mVoucher.MaxSeats"
                :labelForInput="false"
                :label="$t('Components.Vouchers.Settings.Label_MaximalSeats')"
              />
            </div>
            <div class="row">
              <ui-base-input-field
                v-model="mVoucher.MinHours"
                :labelForInput="false"
                :label="$t('Components.Vouchers.Settings.Label_MinimalHours')"
              />
              <ui-base-input-field
                v-model="mVoucher.MaxHours"
                :labelForInput="false"
                :label="$t('Components.Vouchers.Settings.Label_MaximalHours')"
              />
            </div>

            <div class="checkboxes-right">
              <label class="label has-margin-bottom">{{
                $t('Components.Vouchers.Settings.Label_ValidityDays')
              }}</label>
              <div class="main">
                <div class="checkboxes-column">
                  <ui-base-checkbox
                    :label="$t('Components.Vouchers.Settings.Label_Monday')"
                    v-model="mVoucher.Days"
                    :checkValue="2"
                  />
                  <ui-base-checkbox
                    :label="$t('Components.Vouchers.Settings.Label_Tuesday')"
                    v-model="mVoucher.Days"
                    :checkValue="3"
                  />
                  <ui-base-checkbox
                    :label="$t('Components.Vouchers.Settings.Label_Wednesday')"
                    v-model="mVoucher.Days"
                    :checkValue="4"
                  />
                  <ui-base-checkbox
                    :label="$t('Components.Vouchers.Settings.Label_Thursday')"
                    v-model="mVoucher.Days"
                    :checkValue="5"
                  />
                  <ui-base-checkbox
                    :label="$t('Components.Vouchers.Settings.Label_Friday')"
                    v-model="mVoucher.Days"
                    :checkValue="6"
                  />
                  <ui-base-checkbox
                    :label="$t('Components.Vouchers.Settings.Label_Saturday')"
                    v-model="mVoucher.Days"
                    :checkValue="7"
                  />
                  <ui-base-checkbox
                    :label="$t('Components.Vouchers.Settings.Label_Sunday')"
                    v-model="mVoucher.Days"
                    :checkValue="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import voucherProvider from '@/providers/voucher'
import Message from '@/components/UI/Message'

export default {
  name: 'modal-edit-voucher-settings',
  components: {
    BaseFieldTemplate: () => import('@/components/UI/Form/BaseFieldTemplate'),
    message: Message,
  },

  props: {
    voucher: {
      type: Object,
      default: null,
    },

    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      mVoucher: this.$objectHelper.cleanSource(this.voucher),
      voucherValidDates: {
        start: new Date(this.voucher.ValidFrom),
        end: new Date(this.voucher.ValidUntil),
      },
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),

    meetingtypes() {
      let meetingtypes = []

      if (this.channel) {
        meetingtypes = this.channel.Meetingtypes
      }

      if (this.locationState) {
        meetingtypes = this.locationState.Meetingtypes
      }

      return meetingtypes
    },
  },

  methods: {
    ...mapMutations('voucherStore', ['setVoucher']),

    setVoucherValidDates(val) {
      this.mVoucher.ValidFrom = this.$options.filters.dateObjectIsoDateString(
        val.start
      )
      this.mVoucher.ValidUntil = this.$options.filters.dateObjectIsoDateString(
        val.end
      )
    },

    saveVoucher() {
      this.isSaving = true
      this.mVoucher.LocationId = this.locationId

      voucherProvider.methods
        .saveVoucher(this.mVoucher)
        .then((response) => {
          if (response.status === 200) {
            this.isSavingSuccess = true
            this.setVoucher(response.data)

            setTimeout(() => {
              this.$emit('voucherEdited', response.data)
              this.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          this.isSavingError = true
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-settings {
  display: flex;
  .column {
    display: flex;
    flex-direction: column;
    padding: 0;
    &:first-child {
      margin-right: 30px;
      > .field {
        &:first-child,
        &:nth-child(2) {
          width: 50%;
        }
      }
    }
    .checkboxes-left {
      display: flex;
      flex-direction: column;
      .checkboxes-row {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        > div {
          margin-right: 20px;
          margin-bottom: 15px;
        }
      }
    }
    .checkboxes-right {
      display: flex;
      flex-direction: column;
      .main {
        display: flex;
        .checkboxes-column {
          display: flex;
          flex-direction: column;
          width: 47.5%;
          flex-wrap: wrap;
          &:first-child {
            margin-right: 5%;
          }
          > div {
            margin-right: 20px;
            margin-bottom: 15px;
          }
        }
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > .field {
        width: 47.5%;
      }
    }
  }
}
</style>
